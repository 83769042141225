<template>
  <div>
    <h1>Scheduling</h1>

    <div class="w-full lg:w-2/5 bg-white shadow-md leading-loose p-6">
      <div>
        <label class="block font-bold text-gray-600 pb-3">
          Status:
        </label>
        <select v-model="enabled" class="w-full h-9 px-3 py-1 text-gray-700 bg-gray-200 rounded">
          <option value="true">Enabled</option>
          <option value="false">Disabled</option>
        </select>
      </div>
      <div>
        <label class="block font-bold text-gray-600 py-3">
          Expression:
        </label>
        <input class="w-full px-3 py-1 text-gray-700 bg-gray-200 rounded" v-model="crontab" type="text">
      </div>
      <div class="py-3 text-gray-600">
        See examples on expressions <a href="https://crontab.guru/examples.html" target="_blank" style="font-weight: bold; color: blue;">here</a>
      </div>
      <div>
        <button class="pt-1 pr-3 pb-1 pl-3 text-white tracking-wider bg-gray-500 rounded" type="button" @click="updateScheduling">Save changes</button>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: ['projectId'],

  data() {
    return {
      enabled: null,
      crontab: null,
    }
  },
  created () {
    axios.get(`${process.env.VUE_APP_API_URL}/projects/schedule/${this.projectId}`)
      .then(response => {
        this.enabled = response.data.schedule_enabled;
        this.crontab = response.data.schedule_crontab;
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    updateScheduling(evt) {
      evt.preventDefault();

      axios.put(`${process.env.VUE_APP_API_URL}/projects/schedule/${this.projectId}`, {
          enabled: this.enabled,
          crontab: this.crontab
        }).catch(error => {
          console.log(error)
        })
    }
  }
}
</script>